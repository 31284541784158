import React, { Component } from 'react';
import Tile from './Tile';
import { TILE_COUNT, GRID_SIZE, BOARD_SIZE } from './constants';
import { canSwap, shuffle, swap, isSolved } from './helpers';

class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiles: [...Array(TILE_COUNT).keys()],
      isStarted: false,
    };
  }

  shuffleTiles = () => {
    const { tiles } = this.state;
    const shuffledTiles = shuffle(tiles);
    this.setState({ tiles: shuffledTiles });
  }

  swapTiles = (tileIndex) => {
    const { tiles } = this.state;
    if (canSwap(tileIndex, tiles.indexOf(tiles.length - 1))) {
      const swappedTiles = swap(tiles, tileIndex, tiles.indexOf(tiles.length - 1));
      this.setState({ tiles: swappedTiles });
    }
  }

  handleTileClick = (index) => {
    this.swapTiles(index);
  }

  handleShuffleClick = () => {
    this.shuffleTiles();
  }

  handleStartClick = () => {
    this.shuffleTiles();
    this.setState({ isStarted: true });
  }

  render() {
    const { imgUrl } = this.props;
    const { tiles, isStarted } = this.state;
    const pieceWidth = Math.round(BOARD_SIZE / GRID_SIZE);
    const pieceHeight = Math.round(BOARD_SIZE / GRID_SIZE);
    const style = {
      width: BOARD_SIZE,
      height: BOARD_SIZE,
    };
    const hasWon = isSolved(tiles);

    return (
      <>
        <ul style={style} className="board">
          {tiles.map((tile, index) => (
            <Tile
              key={tile}
              index={index}
              imgUrl={imgUrl}
              tile={tile}
              width={pieceWidth}
              height={pieceHeight}
              handleTileClick={this.handleTileClick}
            />
          ))}
        </ul>
        {hasWon && isStarted && <div>Puzzle solved 🧠 🎉</div>}
        {!isStarted ? (
          <button onClick={this.handleStartClick}>Shuffle</button>
        ) : (
          <button onClick={this.handleShuffleClick}>Shuffle</button>
        )}
      </>
    );
  }
}

export default Board;
