import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-parallax';
import Board from './Board';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './App.css';

const sections = [
  {
    title: "Puzzle Challenge",
    bgImage: 'Background-Above.JPG',
    pzImages: [
      { title: "2018", bgImage: '2018.JPG'},
      { title: "2019", bgImage: '2019.JPG'},
      { title: "2020", bgImage: '2020.JPG'},
      { title: "2021", bgImage: '2021.JPG'},
      { title: "2022", bgImage: '2022.JPG'},
      { title: "2023", bgImage: '2023.JPG'},
      { title: "2024", bgImage: '2024.JPG'}
    ]
  },
  {
    title: "Story",
    bgImage: 'Background-Below.JPG',
    pzImages: [
      { title: "2018", bgImage: '2018.JPG'},
      { title: "2019", bgImage: '2019.JPG'},
      { title: "2020", bgImage: '2020.JPG'},
      { title: "2021", bgImage: '2021.JPG'},
      { title: "2022", bgImage: '2022.JPG'},
      { title: "2023", bgImage: '2023.JPG'},
      { title: "2024", bgImage: '2024.JPG'}
    ]
  }
];

const App = () => {
  const [parallaxStrength, setParallaxStrength] = useState(250);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPuzzleImage, setSelectedPuzzleImage] = useState(sections[0].pzImages[0].bgImage);
  const [selectedPuzzleTitle, setSelectedPuzzleTitle] = useState(sections[0].pzImages[0].title);

  useEffect(() => {
    const updateParallaxStrength = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setParallaxStrength(200);
      } else {
        setParallaxStrength(500);
      }
    };

    window.addEventListener('resize', updateParallaxStrength);
    return () => window.removeEventListener('resize', updateParallaxStrength);
  }, []);

  const handleOpenModal = () => {
    const randomIndex = Math.floor(Math.random() * sections[0].pzImages.length);
    setSelectedPuzzleImage(sections[0].pzImages[randomIndex].bgImage);
    setSelectedPuzzleTitle(sections[0].pzImages[randomIndex].title);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <div>

    {/* <div style={{ padding: '20px', textAlign: 'center', width: '100%', opacity: 0.9 }}>
      <Typography sx={{ mt: 2, textAlign: 'center' }}>
        This site is a tribute to my best friend, soulmate, and partner, Christine Vu, in celebration of Valentine's Day, 2024, I love you.
      </Typography>
    </div> */}


      {sections.map((section, index) => (
        <Parallax key={index} bgImage={require(`./assets/images/bgImages/${section.bgImage}`)} strength={parallaxStrength}>
          <div style={{ height: '110vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="title-container">
            <h2 style={{ textAlign: 'center' }} className="chapter-title">{section.title}</h2>
              
              {section.title !== "Story" && (
                <div className="button-container">
                  <button onClick={handleOpenModal}>Start</button>
                </div>
              )}
              
              {section.title === "Puzzle Challenge" && (
                <Modal
                  open={isModalOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: '500px',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 'auto',
                    backgroundImage: `url(${require(`./assets/images/puzzle/regular/${selectedPuzzleImage}`)})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    "&::before": { // Apply the blur effect using a pseudo-element
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundImage: `url(${require(`./assets/images/puzzle/regular/${selectedPuzzleImage}`)})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      filter: 'blur(8px)', // Adjust the blur radius as needed
                      zIndex: -1,
                    }
                  }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
                      {selectedPuzzleTitle}
                    </Typography>
                    <div className="flipper">
                      <div className="front">
                        <img src={require(`./assets/images/puzzle/cartoon/${selectedPuzzleImage}`)} alt="Puzzle" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                      </div>
                      <div className="back"></div>
                    </div>
                    <Board imgUrl={require(`./assets/images/puzzle/cartoon/${selectedPuzzleImage}`)} />
                  </Box>
                </Modal>
              )}
            {/* {section.title === "Story" && (
              <div style={{ padding: '20px', textAlign: 'center', width: '100%' }}>
                <Typography variant="h6" component="h2" style={{ textAlign: 'center' }}>
                  Feature coming soon
                </Typography>
                <Typography sx={{ mt: 2, textAlign: 'center' }}>
                  Working hard to bring you an immersive storytelling experience. Stay tuned for interactive AI stories that will captivate your imagination.
                </Typography>
              </div>
            )} */}

            </div>
          </div>
        </Parallax>

      ))}
    </div>
  );
};

export default App;
